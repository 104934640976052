import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import * as constants from "../style/constants";
import { useMediaQuery } from "react-responsive";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import bubbles from "../images/mobile/Group6.png";
import bubblesSVG from "../images/mobile/Group7.svg";
import bubblesT from "../images/tablet/bubblesT.png";
import bubblesD from "../images/desktop/bubbles.png";
const Container = styled.div`
  margin-top: -75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin-top: 0px;
    flex-direction: row;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 0px;
    flex-direction: row;
  }
`;
const ResponsiveImg = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    query {
      SmobileImage: file(relativePath: { eq: "mobile/Group6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TabletImage: file(relativePath: { eq: "tablet/bubblesT.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DesktopImage: file(relativePath: { eq: "desktop/bubbles.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const sources = [
    {
      ...data.SmobileImage.childImageSharp.fluid,
      media: `(max-width: 424px)`,
    },
    {
      ...data.TabletImage.childImageSharp.fluid,
      media: `(min-width: 768px) and (max-width: 1439px)`,
    },
    {
      ...data.DesktopImage.childImageSharp.fluid,
      media: `(min-width: 1440px)`,
    },
  ];
  const isBigPhone = useMediaQuery({
    query: "(min-width: 425px) and (max-width: 767px)",
  });
  const isSmallPhone = useMediaQuery({ query: "(max-width: 424px)" });
  const isComputer = useMediaQuery({ query: "(min-width: 1440px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1439px)",
  });
  return (
    <>
      <MediaQuery maxWidth={767}>
    <Img className={className} fluid={sources} imgStyle={{
      objectFit:'contain',
    }} style={{overflow:''}}>
      {children}
    </Img>
      </MediaQuery>
      <MediaQuery minWidth={768}>
    <Img className={className} fluid={sources} imgStyle={{
      objectFit:'contain',
      paddingLeft:'60px',
      paddingTop:'30px'
    }}style={{overflow:''}}>
      {children}
    </Img>
      </MediaQuery>
      </>
  );
};
const IMG = styled(ResponsiveImg)`
  width: 100%;
  height: auto;
  z-index: 1;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 56%;
    padding-left: 60px;
    padding-top: 30px;
  }
  @media screen and (min-width: 1440px) {
    width: 56%;
    object-fit:contain;
    padding-left: 60px;
    padding-top: 30px;
  }
`;
const Textdiv = styled.div`
  width: 260px;
  text-align: center;
  font-size: 18px;
  color: ${constants.black};
  line-height: 30px;
  margin-top: 40px;
  @media screen and (min-width: 425px) and (max-width: 767px) {
    font-size: 25px;
    line-height: 40px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    margin-left: -80px;
    margin-top: 120px;
    width: 40%;
  }
  @media screen and (min-width: 1440px) {
    text-align: left;
    font-size: 35px;
    line-height: 60px;
    margin-left: -80px;
    margin-top: 120px;
    width: 30%;
  }
`;
export default function Bubbles({ children }) {
  return (
    <Container>
      <IMG />
      <Textdiv>{children}</Textdiv>
    </Container>
  );
}
