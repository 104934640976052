import * as React from "react";
import Navbar from "../components/Navbar";
import "normalize.css";
import * as constants from "../style/constants";
import styled from "styled-components";
import "../style/Fonts.css";
import "../style/layouts.css";
import Layout from "../components/layout";
import Footer from "../components/footer";
import UEh1 from "../components/heading";
import Container from "../components/textcontainer";
import PictureCard from "../components/picturecard";
import SubTitle from "../components/subtitle";
import Content from "../components/content";
import Bubbles from "../components/bubbles";
import Controller from "../components/controller";
import HomeBackground from "../components/HomeBackground";
import main1 from "../images/main1.jpg";
import main2 from "../images/main2.jpg";
import main3 from "../images/main3.jpg";
import Partner from "../components/partners";
const StyledMain = styled.main`
  background-color: ${constants.white};
  font-family: ${constants.Dfont}, ${constants.Cfont};
`;
const IndexPage = ({data}) => {
  return (
    <Layout>
      <StyledMain>
        <Navbar />
        <title>Home Page</title>
        <HomeBackground>
          <SubTitle>
            <span style={{ color: `${constants.brightred}` }}>优译</span>翻译
            <br />
            由一群对游戏与文字            
            <br />
            充满热爱的年轻人组成, 
            <br /> 
            我们希望通过游戏带来的乐趣, 
            <br /> 
            为不同的文化构筑桥梁
          </SubTitle>
        </HomeBackground>
        <div style={{ marginTop: "500px" }}></div>
        <Controller>
          <Content bold={"y"}>
        我们的工作不止于简单的翻译，
            <Content>
              我们会深入理解游戏创作背景，目标受众的文化背景以及生活习惯，在翻译中加入受众喜爱的语言接近他们的生活，让您的游戏成为他们生活的一部分。
            </Content>
          </Content>
        </Controller>
        <Bubbles>
          以多年的游戏经验配合深厚的文化专业背景,让您的受众始于游戏,陷于情节,忠于文化，成为您游戏的忠实爱好者。
        </Bubbles>
        <div style={{ marginTop: "5rem" }}></div>
        <Container responsive={"column"}>
          <UEh1>
            <span style={{ color: `${constants.offred}` }}>与众不同</span>
            的翻译服务
          </UEh1>
          <PictureCard
            src={data.Image2.childImageSharp.fluid}
            title={"文化融合"}
            width={"100%"}
            height={"auto"}
          >
            我们会找出游戏中与目标市场文化环境不一致的内容，并给出解决建议，帮好您的游戏更好地融入当地市场
          </PictureCard>
          <PictureCard
            src={data.Image3.childImageSharp.fluid}
            title={"专业专研"}
            width={"100%"}
            height={"auto"}
          >
    我们的团队已有多年的经验专注游戏翻译，并不断提升专业技能，给你最顶尖的游戏翻译协助
          </PictureCard>
          <PictureCard
            src={data.Image1.childImageSharp.fluid}
            title={"沉浸乐趣"}
            width={"100%"}
            height={"auto"}
          >
            我们擅用玩家爱好的语言进行翻译，融入他们的日常用语，让他们全情投入不因普通翻译带来的语言差异而分神。用最具真实联系的语言，带您的游戏走向更多市场。
          </PictureCard>
        </Container>
        <div style={{ marginTop: "4rem" }}></div>
        <Container>
          <UEh1>我们帮助全球客户伙伴走向国际化</UEh1>
        </Container>
        <div style={{ marginTop: "4rem" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "90%", height: "240px" }}>
            <Partner />
          </div>
        </div>
      </StyledMain>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
