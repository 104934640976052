import styled from 'styled-components'
import UEp from "./paragragh";
import * as constants from "../style/constants";
import React from 'react'

const Content = styled(UEp)`
  @media screen and (max-width: 375px) {
    font-family: ${constants.Cfont};
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: ${constants.white};
  }
  @media screen and (min-width: 376px) and (max-width: 767px) {
    font-family: ${constants.Cfont};
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: ${constants.white};
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-family: ${constants.Cfont};
    font-size: 18px;
    line-height: 30px;
    text-align:left;
    color: ${constants.white};
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    font-family: ${constants.Cfont};
    font-size: 20px;
    line-height: 33px;
    text-align:left;
    color: ${constants.white};
  }
  @media screen and (min-width: 1440px) {
    font-family: ${constants.Cfont};
    font-size: 28px;
    line-height: 52px;
    text-align:left;
    color: ${constants.white};
  }

`;
export default function content({children, bold}) {
    return (
        <Content bold={bold}>{children}</Content>
    )
}



