import Img from "gatsby-image";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";



export default function MobileIMG ({ className }) {
  const data = useStaticQuery(
    graphql`
          query {
        mobile: file(relativePath: { eq: "mobile/BackgroundCTP.png" }) {
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid
            }
          }
        }
      }`
  );
  const imageData = data.mobile.childImageSharp.fluid;
  return (
    <Img className={className} fluid={imageData}/>
  );
};