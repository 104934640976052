import React from "react";
import * as constants from "../style/constants";
import CmobileIMG from "./controllerIMG"
import BackgroundImage from "gatsby-background-image";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import CTImg from "../images/mobile/BackgroundCTP.png";
import DBG from "../images/desktop/background.png"
import ctrlerR from "../images/tablet/ControllerR.svg";
const Gradient = styled.div`
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: rows;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 270px;
    background: linear-gradient(179.31deg, #933724 -2.58%, #DE354C 82.22%);
  }
  @media screen and (min-width: 376px) and (max-width: 767px) {
    display: flex;
    flex-direction: rows;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 88vw;
    background: linear-gradient(179.31deg, #933724 -2.58%, #DE354C 82.22%);
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: rows;
    align-items: center;
    justify-content: center;
    width: 48%;
    height: 34.95vw;
    margin-top: 0.95%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    display: flex;
    flex-direction: rows;
    align-items: center;
    justify-content: center;
    width: 48%;
    height: 34.95vw;
    margin-top: 0.95%;
  }
  @media screen and (min-width: 1440px) {
    display: flex;
    flex-direction: rows;
    align-items: center;
    justify-content: center;
    width: 48%;
    height: 34.96vw;
    margin-top: 1.06%;
  }
`;
const Inside = styled.div`
  @media screen and (max-width: 375px) {
    width: 258px;
    text-align: center;
  }
  @media screen and (min-width: 376px) and (max-width: 767px) {
    width: 280px;
    text-align: center;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 500px;
    text-align: center;
    padding-left: 50px;
    display: flex;
    align-item: center;
  }
  @media screen and (min-width: 1024px) {
    width: 500px;
    text-align: center;
    padding-left: 50px;
    display: flex;
    align-item: center;
  }
`;

const IMG = styled(CmobileIMG)`
  width: 100%;
  height: auto;
  margin-top: -2px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 52%;
    margin-left: -6px;
  }
  @media screen and (min-width: 1024px) {
    width: 52%;
    height: auto;
    margin-left: -2px;
  }
`;
const Sdiv = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    background-color: ${constants.purple};
  }
`;
const Idiv = styled.div`
  background-color: ${constants.purple};
  width: 100%;
  height: 55vw;
  background-image: url(${DBG});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (min-width: 1920px) {
    height: 50vw;
  }
`;
const Pdiv = styled.div`
  width: 35vw;
  padding-top: 3vw;
  padding-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  @media screen and (min-width: 1440px) {
    padding-left: 80px;
  }
  @media screen and (min-width: 1920px) {
    width: 31vw;
  }
`;
export default function Controller({ children }) {
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <div>
      {!isBigScreen && (
        <Sdiv>
          <Gradient>
            <Inside>{children}</Inside>
</Gradient>
          <IMG src={(!isBigScreen && CTImg) || ctrlerR}></IMG>
        </Sdiv>
      )}
      {isBigScreen && (
        <Idiv>
          <Pdiv>{children}</Pdiv>
        </Idiv>
      )}
    </div>
  );
}
