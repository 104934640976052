import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";

const Styledh1 = styled((props) => <h1 {...props} />)`
  color: ${constants.black};
  line-height: ${constants.H1Height};
  font-family: ${constants.CfontMed};
  font-size: ${constants.H1fontsize};

  @media screen and (max-width: 375px) {
    font-size: ${constants.H1fontsizeSP};
    line-height: ${constants.H1HeightSP};
    margin-top:0;
    text-align:center;
  }
  @media screen and (min-width: 376px) and (max-width: 1024px) {
    font-size: ${constants.H1fontsizeP};
    line-height: ${constants.H1HeightP};
    margin-top:0;
    text-align:center;
  }
`;
export default function heading({ children }) {
  return <Styledh1>{children}</Styledh1>;
}
