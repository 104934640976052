import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"



const StyledImg = styled.div`
  position: absolute;
  width: 100%;
  height: 264px;
  object-fit:cover;
  @media screen and (min-width: 414px) and (max-width: 767px) {
  height: 340px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
  height: 500px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width:100%;
    height:500px;
  }
  @media screen and (min-width: 1440px)  {
    width:100%;
    height:100vh;
  }
`;
const StyledGradient = styled.div`
  position: absolute;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  width: 100%;
  height: 264px;
  background: linear-gradient(
    80.95deg,
    #283747 6.26%,
    rgba(40, 55, 71, 0.42) 54.37%,
    rgba(196, 196, 196, 0) 96.82%
  );
  @media screen and (min-width: 414px) and (max-width: 767px) {
  height: 340px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
  height: 500px;
  align-items:start;
  justify-content: center;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
  height: 500px;
  justify-content:center;
  align-items:start;
  }
  @media screen and (min-width: 1440px) {
  height: 100vh;
  justify-content:center;
  align-items:start;
  }
`;
const Background = ({ children, props }) => {
  const data = useStaticQuery(graphql`
  query MyQuery {
  SmobileImage: file(relativePath: {eq: "mobile/HomeBackgroundSP.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
   mobileImage: file(relativePath: {eq: "mobile/HomeBackgroundTB.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  desktopImage: file(relativePath: {eq: "desktop/HomeBG.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}

  `)
  const isLaptop= useMediaQuery({ query: "(min-width: 1024px) and (max-width: 1499px)" });
    const sources = [
    data.SmobileImage.childImageSharp.fluid,
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: `(min-width: 414px) and (max-width: 1023px)`,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]
  return (
  <div>
    <StyledImg>
    <Img fluid={sources} style={{position:''}}/>
    </StyledImg>
    <StyledGradient>{children}</StyledGradient>
  </div>
)};
export default Background;
