import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Square from "../images/Square_Enix.svg";
import Ubisoft from "../images/Ubisoft_2017.svg";
import styled from "styled-components";
import Tencent from "../images/Ten_Cent.png";
import Bilibili from "../images/Bilibili_Logo_Blue.svg"
import netseek from "../images/netseek_logo.png"

const ImageHolder = styled.div`
  width: 80%;
  height: 133px;
  margin:auto;
  display:flex;
  justify-content: center;
`;
const handleDragStart = (e) => e.preventDefault();

const items = [
  <ImageHolder>
    <img
      src={Square}
      style={{
        width: "100%",
        objectFit: "contain",
      }}
      onDragStart={handleDragStart}
      className="yours-custom-class"
    />
  </ImageHolder>,
  <ImageHolder>
    <img
      src={Ubisoft}
      style={{
        objectFit: "contain",
        width: "100%",
      }}
      onDragStart={handleDragStart}
      className="yours-custom-class"
    />
  </ImageHolder>,
  <ImageHolder>
    <img
      src={Tencent}
      style={{
        objectFit: "contain",
        width: "100%",
      }}
      onDragStart={handleDragStart}
      className="yours-custom-class"
    />
  </ImageHolder>,
  <ImageHolder>
    <img
      src={netseek}
      style={{
        objectFit: "contain",
        width: "100%",
      }}
      onDragStart={handleDragStart}
      className="yours-custom-class"
    />
  </ImageHolder>,
  <ImageHolder>
    <img
      src={Bilibili}
      style={{
        objectFit: "contain",
        width: "100%",
      }}
      onDragStart={handleDragStart}
      className="yours-custom-class"
    />
  </ImageHolder>,
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const Gallery = () => {
  return (
    <AliceCarousel
      mouseTracking
      items={items}
      infinite={true}
      autoPlay={true}
      autoPlayInterval={1500}
      disableButtonsControls={true}
      responsive={responsive}
    />
  );
};
export default Gallery;
