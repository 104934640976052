import * as React from "react";
import { graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";
import Desktop from "../Responsive/desktop";
import SmallPhone from "../Responsive/smallphone"
import Phone from "../Responsive/phone"
import Tablet from "../Responsive/tablet"
import Laptop from "../Responsive/laptop"
import "normalize.css";
const IndexPage = ({data}) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  const isSmallPhone= useMediaQuery({ query: "(max-width: 413px)" });
  const isPhone= useMediaQuery({ query: "(min-width: 414px) and (max-width: 767px)" });
  const isTablet= useMediaQuery({ query: "(min-width: 768px) and (max-width: 1023px)" });
  const isLaptop= useMediaQuery({ query: "(min-width: 1024px) and (max-width: 1439px)" });
  return <>
  {isBigScreen && <Desktop data={data}/>}
  {isSmallPhone&& <SmallPhone data={data}/>}
  {isPhone&& <Phone data={data}/>}
  {isTablet&& <Tablet data={data}/>}
  {isLaptop&& <Laptop data={data}/>}
  </>;
};
export default IndexPage;
export const query = graphql`
  query{
    Image1: file(relativePath: {eq: "main1.jpg"}) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
    Image3: file(relativePath: {eq: "main3.jpg"}) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
    Image2: file(relativePath: {eq: "main2.jpg"}) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;