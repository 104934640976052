import Subtitle from './subtitle'
import React from 'react'
import styled from 'styled-components'

const HomeSubtitle = styled(Subtitle)`
  @media screen and (max-width: 375px) {
    font-size: 18px;
    line-height: 30px;
    margin-bottom:20px;
  }
  @media screen and (min-width: 376px) and (max-width: 767px) {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin-bottom:20px;
  }
`;

export default function subtitle({children}) {
    return (
        <HomeSubtitle>{children}</HomeSubtitle>
    )
}